<template>
  <section
    ref="MainContent"
    class="fixed top-0 z-50 flex items-center justify-center w-full h-screen overflow-hidden transform translate-x-0 left-full"
  >
    <div
      data-dialog-box
      id="CaseDialog"
      class="relative z-10 w-full max-w-screen-sm opacity-0 mx-7 md:mx-10"
    >
      <div
        class="relative z-10 w-full border rounded-md p-7 md:p-14 bg-main_white border-main_black"
      >
        <h2 class="w-full mb-5 text-2xl font-black md:text-3xl">
          {{ case_dialog.name }}
        </h2>
        <ul class="w-full mb-5">
          <li class="flex items-start w-full mb-2">
            <span
              class="w-fit shrink-0 text-sm md:text-base font-bold bg-main_white border border-main_green px-2 py-[2px] mr-2"
              >產業類別</span
            >
            <span
              class="w-full text-sm font-bold text-justify md:text-base text-main_green"
              >{{ case_dialog.type }}</span
            >
          </li>
          <li class="flex items-start w-full mb-2">
            <span
              class="w-fit shrink-0 text-sm md:text-base font-bold bg-main_white border border-main_green px-2 py-[2px] mr-2"
              >製作重點</span
            >
            <span
              class="w-full text-sm font-bold text-justify md:text-base text-main_green"
              >{{ case_dialog.outline }}</span
            >
          </li>
        </ul>
        <p
          class="w-full mb-5 text-sm font-medium text-justify md:text-base"
          v-html="case_dialog.content"
        ></p>
        <a
          :href="case_dialog.link"
          target="_blank"
          class="flex items-center justify-center w-full py-2 border bg-main_yellow border-main_black"
        >
          <span class="mr-1 text-sm font-bold">觀看作品</span>
          <span class="text-xs icon-icon_arrow"></span
        ></a>
      </div>
      <div
        @click="$store.commit('CloseCaseDialog')"
        class="absolute top-0 right-0 z-10 w-10 h-10 transform -translate-x-1/2 -translate-y-1/2 md:w-14 md:h-14"
      >
        <button
          class="relative z-10 flex items-center justify-center w-full h-full border rounded-full bg-main_white border-main_black"
        >
          <span class="text-base icon-icon_close md:text-xl"></span>
        </button>
        <span
          class="absolute z-0 top-0 left-0 right-0 bottom-0 bg-main_black border border-main_black rounded-full transform translate-x-[2px] translate-y-[2px]"
        ></span>
      </div>

      <span
        class="absolute z-0 top-0 left-0 right-0 bottom-0 bg-main_green border border-dashed border-main_black rounded-md transform translate-x-1.5 translate-y-1.5"
      ></span>
    </div>

    <div
      data-dialog-bg
      class="absolute top-0 left-0 z-0 w-full h-full opacity-0 dialog_bg bg-main_black bg-opacity-80 transtion-all duraction-300"
    ></div>
  </section>
</template>

<script>
import { dialog_animation } from '@/gsap/dialog';
export default {
  name: 'HomeCaseDialog',
  data() {
    return {
      dialog_animation: null,
    };
  },

  methods: {},
  watch: {
    casedialog_status() {
      if (this.casedialog_status) {
        document.body.style.overflowY = 'hidden';
        this.dialog_animation.open();
      } else {
        document.body.style.overflowY = 'auto';
        this.dialog_animation.close();
      }
    },
  },
  mounted() {
    this.dialog_animation = new dialog_animation(this.$refs.MainContent);
  },

  computed: {
    casedialog_status() {
      return this.$store.state.case_dialog.status;
    },
    case_dialog() {
      return this.$store.state.case_dialog;
    },
  },
};
</script>
