<template>
  <section
    id="Case"
    ref="MainContent"
    class="relative z-10 w-full overflow-hidden"
  >
    <div
      class="relative z-10 w-full max-w-screen-xl mx-auto my-40 xl:w-3/4 md:my-60"
    >
      <header class="relative z-10 w-full">
        <div
          v-if="window_size > 768"
          data-marquee-box
          data-duration="10"
          data-direction="left"
          class="hidden w-full overflow-hidden border-b md:flex border-main_black"
        >
          <div
            data-marquee-item
            class="flex items-center flex-shrink-0 py-2 mb-2"
            v-for="text_item in 3"
            :key="`text_${text_item}`"
          >
            <span
              class="flex-shrink-0 mr-5 text-4xl font-black md:text-5xl text-main_black"
            >
              幫助多個品牌架設網站
            </span>
            <span
              class="flex-shrink-0 block px-10 py-2 mr-5 text-base font-bold border rounded md:text-xl font-any-body text-main_black bg-main_white border-main_black w-fit"
            >
              Our Client
            </span>
            <span
              class="flex-shrink-0 mr-5 text-xl icon-star md:text-2xl text-main_yellow"
            >
            </span>
          </div>
        </div>
        <div class="block py-2 mx-auto text-center md:hidden">
          <span
            class="block mb-5 text-4xl font-black md:text-5xl text-main_black"
          >
            幫助多個品牌架設網站
          </span>
          <span
            class="block px-10 py-2 mx-auto text-base font-bold border rounded md:text-xl font-any-body text-main_black bg-main_white border-main_black w-fit"
          >
            Our Client
          </span>
          <span
            class="flex-shrink-0 mr-5 text-xl icon-star md:text-2xl text-main_yellow"
          >
          </span>
        </div>
      </header>

      <ul class="relative z-10 flex flex-wrap items-stretch w-full">
        <li
          v-for="(item, item_index) in case_card_list"
          :key="`card_${item_index}`"
          class="relative z-10 w-full md:w-1/2 xl:w-1/3"
        >
          <div
            :class="GetCardStyle(item_index)"
            class="relative z-10 flex flex-col w-full h-full px-8 py-16 border-b md:px-10 xl:px-16 md:py-16 border-main_black"
          >
            <div class="relative z-10 w-full mb-6 aspect-square">
              <img
                :src="item.image"
                :alt="item.name"
                class="relative z-10 block w-full border rounded-md rounded-br-none border-main_black"
              />
              <span
                class="absolute z-0 top-0 left-0 right-0 bottom-0 bg-main_yellow border border-dashed border-main_black rounded-md rounded-br-none transform translate-x-1.5 translate-y-1.5"
              ></span>
            </div>
            <h3 class="mb-1 text-2xl font-black text-main_black">
              {{ item.name }}
            </h3>
            <span
              class="flex-1 block mb-6 text-sm font-medium text-main_black"
              >{{ item.outline }}</span
            >

            <div class="flex items-stretch w-full">
              <!-- <router-link
                :to="`/project/${item.id}`"
                class="block w-1/2 py-3 text-sm font-medium text-center transition-all duration-300 border border-r-0 border-dashed text-main_black bg-main_white border-main_black hover:bg-main_green"
              >
                了解更多
              </router-link> -->
              <router-link
                :to="`/project/${item.id}`"
                target="blank"
                class="flex items-center justify-center w-full py-3 transition-all duration-300 border bg-main_yellow border-main_black hover:border-dashed hover:bg-main_green"
              >
                <p class="mr-1 text-sm font-medium text-main_black">觀看作品</p>
                <span class="text-xs icon-icon_arrow text-main_black"></span>
              </router-link>
            </div>
          </div>
        </li>
      </ul>

      <div class="relative z-10 w-full">
        <div
          v-if="window_size > 768"
          data-marquee-box
          data-duration="10"
          data-direction="right"
          class="hidden w-full overflow-hidden md:flex"
        >
          <div
            data-marquee-item
            class="flex items-center flex-shrink-0 py-2 mt-2"
            v-for="text_item in 3"
            :key="`text_${text_item}`"
          >
            <span
              class="flex-shrink-0 mr-5 text-4xl font-black md:text-5xl text-main_black"
            >
              幫助多個品牌架設網站
            </span>
            <span
              class="flex-shrink-0 block px-10 py-2 mr-5 text-base font-bold border rounded md:text-xl font-any-body text-main_black bg-main_white border-main_black w-fit"
            >
              Our Client
            </span>
            <span
              class="flex-shrink-0 mr-5 text-xl icon-star md:text-2xl text-main_yellow"
            >
            </span>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped></style>

<script>
import case_list_data from "@/assets/data/case_list.json";
export default {
  name: "HomeCase",
  data() {
    return {
      case_card_list: case_list_data.case_list,
      window_size: window.innerWidth,
    };
  },
  methods: {
    GetCardStyle(index) {
      let style_list = "";

      if (index % 3 == 2) {
        // xl以上的時候在最右邊的
        style_list += "xl:border-r-0 border-r-none ";
      }

      if (index % 2 != 1) {
        // lg以下的時候在左邊的
        style_list += "md:border-r";
      } else {
        // lg以下的時候在右邊的
        style_list += "lg:border-r md:border-r-0";
      }
      return style_list;
    },
    OpenDialog(item) {
      // 深複製一個item並且跟另一個object合併
      let tmp_case = Object.assign({ status: true }, item);
      this.$store.commit("SetCaseDialog", tmp_case);
    },
    SetGsap() {},
  },

  computed: {
    casedialog_status() {
      return this.$store.state.case_dialog_status;
    },
  },
};
</script>
