import { gsap } from "@/gsap/gsap_loader";
import imagesloaded from "imagesloaded";
import store from "@/store/index";

export function LoadImage() {
  //鎖住body
  document.body.style.overflowY = "hidden";
  store.commit("SetLoading", 1);

  //初始化
  store.commit("SetImageLoaded", false);
  const images = gsap.utils.toArray("img");
  // const loader = document.querySelector('.loader--text');

  //進度條
  // const updateProgress = (instance) => {
  //     loader.innerHTML = `${Math.round(instance.progressedCount * 100 / images.length)}`
  // };

  //完成事件
  const showDemo = () => {
    //復原body
    document.body.style.overflowY = "auto";
    window.scrollTo(0, 0);
    store.commit("SetLoading", -1);

    //設定讀取完成
    store.commit("SetImageLoaded", true);
    console.log("all images have been loaded!");
  };

  imagesloaded(images).on("always", showDemo);
  // .on('progress', updateProgress)
}
