<template>
  <div ref="MainContent" id="app" class="overflow-x-hidden">
    <MainHeader />

    <MainLoading />

    <router-view @set-gsap="SetGsap" class="relative z-10 w-full" />

    <MainFooter />
    <PageBackground />

    <MenuPage />
    <MainDialog />
    <HomeCaseDialog ref="HomeCaseDialog" />
  </div>
</template>

<style src="@/assets/css/output.css"></style>

<script>
import MainHeader from '@/components/MainHeader.vue';
import MainFooter from '@/components/MainFooter.vue';
import PageBackground from '@/components/PageBackground.vue';
import MenuPage from '@/components/MenuPage.vue';
import MainDialog from '@/components/MainDialog.vue';
import HomeCaseDialog from '@/components/home/CaseDialog.vue';
import MainLoading from '@/components/MainLoading.vue';
import { marquee } from '@/gsap/marquee';

export default {
  name: 'AppView',
  components: {
    MainHeader,
    MainFooter,
    PageBackground,
    MenuPage,
    MainDialog,
    MainLoading,
    HomeCaseDialog,
  },
  data() {
    return {
      marquee_animation: null,
    };
  },
  methods: {
    SetGsap() {
      this.marquee_animation = new marquee();
    },
  },
};
</script>
