import { gsap } from '@/gsap/gsap_loader';

export class top_banner {
  constructor(el) {
    this.el = el;
    this.title_box = el.querySelectorAll('[data-title-box]');
    this.deco_left = el.querySelectorAll('[data-deco-left]');
    this.deco_right = el.querySelectorAll('[data-deco-right]');
    //
    this.banner_image = el.querySelectorAll('[data-banner-image]');
    this.banner_text = el.querySelectorAll('[data-banner-text]');
    this.banner_text_outline = el.querySelectorAll(
      '[data-banner-text-outline]'
    );
    gsap.set(this.banner_text_outline, { opacity: 0 });
    this.bg_dot_white = el.querySelector('[data-dot-bg-white]');
    this.bg_dot_green = el.querySelector('[data-dot-bg-green]');
    this.timeline = null;
    this.carousel_timeline = null;
    this.text_timeline = null;
    this.page = 1;

    // mouse tracking
    // this.request = null;
    // this.mouse = { x: 0, y: 0 };
    // this.cx = this.el.offsetWidth / 2;
    // this.cy = this.el.offsetHeight / 2;
    this.rect = this.el.getBoundingClientRect();
    this.mouse = { x: 0, y: 0, moved: false };

    this.setup();
    this.setMouseTracking();
  }

  setup() {
    this.timeline != null ? this.timeline.kill() : '';
    this.timeline = gsap.timeline({
      onComplete: () => {
        setTimeout(() => {
          this.setCarousel();
        }, 5000);
      },
    });
    this.timeline.add(this.setTitleBox(), 'same');
    this.timeline.add(this.setDecoImage(), 'same');
    this.timeline.add(this.setBannerImage(), 'same');
    this.setBannerTextRotate();
  }

  setTitleBox() {
    const tween = gsap.timeline();

    tween.fromTo(
      this.title_box[0],
      {
        opacity: 1,
        scale: 0,
      },
      {
        opacity: 1,
        scale: 1,
        duration: 0.8,
        ease: 'bounce.out',
      },
      'same'
    );

    return tween;
  }

  setDecoImage() {
    const tween = gsap.timeline();
    tween.from(
      this.deco_left,
      {
        scale: 0.5,
        x: '-20%',
        y: '20%',
        duration: 0.4,
      },
      'same'
    );
    tween.from(
      this.deco_right,
      {
        scale: 0.5,
        x: '20%',
        y: '20%',
        duration: 0.4,
      },
      'same'
    );
    return tween;
  }

  setBannerImage() {
    const tween = gsap.timeline();
    tween.from(
      this.banner_image,
      {
        scale: 0,
        ease: 'back.inOut(1.7)',
        duration: 0.6,
      },
      'same'
    );
    tween.from(
      this.banner_text,
      {
        opacity: 0,
        delay: 0.8,
        duration: 1,
      },
      'same'
    );
  }

  setCarousel() {
    this.page == 1 ? this.setSecondCarousel() : this.setFirstCarousel();
    this.page = this.page == 1 ? 2 : 1;
  }

  setFirstCarousel() {
    this.carousel_timeline != null ? this.carousel_timeline.kill() : '';
    this.carousel_timeline = gsap.timeline({
      onComplete: () => {
        setTimeout(() => {
          this.setCarousel();
        }, 5000);
      },
    });

    this.carousel_timeline
      // change title
      .to(
        this.title_box[1],
        {
          scale: 0,
          opacity: 0,
          duration: 0.8,
        },
        'same'
      )
      .fromTo(
        this.title_box[0],
        {
          opacity: 0,
          scale: 0,
        },
        {
          opacity: 1,
          scale: 1,
          duration: 0.8,
          ease: 'bounce.out',
        },
        'same'
      )
      // change bg color
      .to(
        this.el,
        {
          backgroundColor: '#F5F4F0',
          duration: 1,
        },
        'same'
      )
      // change bg image
      .fromTo(
        this.bg_dot_white,
        {
          opacity: 1,
        },
        {
          opacity: 0,
          duration: 0.6,
        },
        'same'
      )
      .fromTo(
        this.bg_dot_green,
        {
          opacity: 0,
        },
        {
          opacity: 1,
          duration: 0.6,
        },
        'same'
      )
      // rotate banner image
      .fromTo(
        this.banner_image,
        {
          rotate: '180deg',
        },
        {
          rotate: '360deg',
          duration: 0.6,
          ease: 'back.inOut(1.7)',
        },
        'same'
      )
      // change banner text
      .to(
        this.banner_text,
        {
          opacity: 1,
          duration: 0.6,
        },
        'same'
      )
      .to(
        this.banner_text_outline,
        {
          opacity: 0,
          duration: 0.6,
        },
        'same'
      );
  }

  setSecondCarousel() {
    this.carousel_timeline != null ? this.carousel_timeline.kill() : '';
    this.carousel_timeline = gsap.timeline({
      onComplete: () => {
        setTimeout(() => {
          this.setCarousel();
        }, 5000);
      },
    });

    this.carousel_timeline
      // change title
      .to(
        this.title_box[0],
        {
          scale: 0,
          opacity: 0,
          duration: 0.8,
        },
        'same'
      )
      .fromTo(
        this.title_box[1],
        {
          opacity: 0,
          scale: 0,
        },
        {
          opacity: 1,
          scale: 1,
          duration: 0.8,
          ease: 'bounce.out',
        },
        'same'
      )
      // change bg color
      .to(
        this.el,
        {
          backgroundColor: '#E2E868',
          duration: 1,
        },
        'same'
      )
      // change bg image
      .fromTo(
        this.bg_dot_white,
        {
          opacity: 0,
        },
        {
          opacity: 1,
          duration: 0.6,
        },
        'same'
      )
      .fromTo(
        this.bg_dot_green,
        {
          opacity: 1,
        },
        {
          opacity: 0,
          duration: 0.6,
        },
        'same'
      )
      // rotate banner image
      .fromTo(
        this.banner_image,
        {
          rotate: '0deg',
        },
        {
          rotate: '180deg',
          duration: 0.6,
          ease: 'back.inOut(1.7)',
        },
        'same'
      )
      // change banner text
      .to(
        this.banner_text,
        {
          opacity: 0,
          duration: 0.6,
        },
        'same'
      )
      .to(
        this.banner_text_outline,
        {
          opacity: 1,
          duration: 0.6,
        },
        'same'
      );
  }

  setBannerTextRotate() {
    this.text_timeline = gsap.timeline();
    this.text_timeline.fromTo(
      this.banner_text,
      {
        rotate: '0deg',
      },
      {
        rotate: '360deg',
        duration: 60,
        ease: 'none',
        repeat: -1,
      },
      'same'
    );
    this.text_timeline.fromTo(
      this.banner_text_outline,
      {
        rotate: '0deg',
      },
      {
        rotate: '360deg',
        duration: 60,
        ease: 'none',
        repeat: -1,
      },
      'same'
    );
  }

  setMouseTracking() {
    this.el.addEventListener('mousemove', (event) => {
      this.mouse.moved = true;
      this.mouse.x = event.clientX - this.rect.left;
      this.mouse.y = event.clientY - this.rect.top;
    });

    gsap.ticker.add(() => {
      if (this.mouse.moved) {
        this.updateMouseTracking(this.deco_left, -100);
        this.updateMouseTracking(this.deco_right, -100);
      }
      this.mouse.moved = false;
    });

    window.addEventListener('resize', () => {
      this.rect = this.el.getBoundingClientRect();
    });
  }

  updateMouseTracking(target, movement) {
    gsap.to(target, 0.5, {
      x: ((this.mouse.x - this.rect.width / 2) / this.rect.width) * movement,
      y:
        ((this.mouse.y - this.rect.height / 2) / this.rect.height) *
        (movement * 2),
    });
  }
}
