<template>
  <section ref="MainContent" class="relative z-10 w-full overflow-hidden">
    <div
      class="relative z-10 w-full max-w-screen-xl mx-auto my-5 xl:w-3/4 px-7 md:px-10 xl:px-0"
    >
      <div
        id="Footer"
        class="relative z-10 flex flex-col w-full pt-10 pb-12 overflow-hidden rounded-lg lg:flex-row lg:items-start px-7 md:px-20 xl:px-32 md:pt-20 md:pb-24"
      >
        <ul class="relative z-10 w-full mb-5 md:mb-10 lg:mb-0 lg:mr-10">
          <li>
            <router-link to="/" class="block w-48 mb-10 md:w-72">
              <img src="/img/logo.webp" alt="阿隆幫你架網站" class="w-full" />
            </router-link>
          </li>
          <li class="flex items-center w-full mb-2">
            <span
              class="px-3 py-1 mr-2 text-sm font-medium border rounded md:text-base text-main_balck border-main_black shrink-0"
              >地址</span
            >
            <p
              class="text-sm font-bold text-justify md:text-base text-main_balck"
            >
              台中市南屯區龍德路一段180號
            </p>
          </li>
          <li class="flex items-center w-full">
            <span
              class="px-3 py-1 mr-2 text-sm font-medium border rounded md:text-base text-main_balck border-main_black"
              >電話</span
            >
            <p
              class="text-sm font-bold md:text-base font-any-body text-main_balck"
            >
              0800-888-553
            </p>
          </li>
        </ul>
        <div class="relative z-10 flex flex-col w-full lg:items-end lg:mt-10">
          <ul class="relative z-10 flex mb-5">
            <li
              v-for="(item, item_index) in contact_card_list"
              :key="`card_${item_index}`"
              class="relative z-10 w-10 h-10 mr-2 md:w-12 md:h-12"
            >
              <a
                :href="item.link"
                target="blank"
                class="contact_card_btn relative z-10 w-full h-full flex justify-center items-center rounded bg-main_yellow border border-main_black transition-all duration-300 transform hover:translate-x-[2px] hover:translate-y-[2px] hover:bg-main_black hover:border-main_green"
              >
                <span
                  :class="item.icon"
                  class="text-xl contact_card_icon md:text-2xl text-main_black"
                ></span>
              </a>
              <span
                class="absolute z-0 top-0 left-0 right-0 bottom-0 bg-main_green border border-dashed border-main_black rounded-md transform translate-x-[3px] translate-y-[3px]"
              ></span>
            </li>
          </ul>
          <div class="relative z-10 w-[180px] md:w-[220px] mb-14 md:mb-20">
            <router-link
              to="/Application"
              class="apply_btn relative z-10 py-3 flex justify-center items-center rounded-full border border-main_yellow bg-main_balck transform hover:translate-x-[3px] hover:translate-y-[3px] hover:bg-main_yellow hover:border-main_black transition-all duration-300"
            >
              <span
                class="relative z-10 mr-2 text-sm font-medium apply_btn_txt md:text-base text-main_yellow"
                >立即申請</span
              >
              <span
                class="relative z-10 text-xs font-medium icon-icon_arrow md:text-sm text-main_yellow"
              ></span>
            </router-link>
            <span
              class="absolute z-0 top-0 left-0 right-0 bottom-0 bg-main_black rounded-full transform translate-x-[3px] translate-y-[3px]"
            ></span>
          </div>
          <small
            class="w-full text-xs font-medium text-justify lg:w-fit text-main_black"
            >Copyright ©
            <a href="https://www.yongxin-design.com" target="_blank"
              >泳欣事業</a
            >
            版權所有</small
          >
        </div>
        <!-- Deco -->
        <span
          class="absolute bottom-0 right-0 z-0 transform translate-x-10 -translate-y-10 lg:top-0 lg:-translate-x-1/3 lg:-translate-y-1/3 w-fit"
        >
          <img
            src="/img/footer_deco.webp"
            alt="阿隆幫你架網站"
            class="w-44 md:w-80"
          />
        </span>
        <span
          class="absolute bottom-0 z-0 transform rotate-45 translate-x-1/2 translate-y-1/2 w-fit"
        >
          <img
            src="/img/footer_deco_2.webp"
            alt="阿隆幫你架網站"
            class="w-44 md:w-80"
          />
        </span>
        <div
          class="absolute top-0 bottom-0 left-0 right-0 z-0 plan_bg_noisy"
        ></div>
      </div>
    </div>
  </section>
</template>

<style scoped>
#Footer {
  background-image: url("@/assets/img/paln_bg_pattern.webp");
  background-size: cover;
  background-color: #e2e868;
  background-position: bottom;
}
.plan_bg_noisy {
  background-image: url("@/assets/img/bg_noisy.webp");
  background-repeat: repeat;
  background-size: 50px;
  opacity: 0.2;
}
.drop_shadow {
  /* -webkit-filter: drop-shadow(3px 3px 0px rgba(27, 28, 30, 1)); */
}
.apply_btn:hover .apply_btn_txt,
.apply_btn:hover .icon-icon_arrow {
  color: #1b1c1e;
}
.contact_card_btn:hover .contact_card_icon {
  color: #e2e868;
}
</style>

<script>
export default {
  name: "MainFooter",
  data() {
    return {
      contact_card_list: [
        {
          icon: "icon-icon_fb",
          link: "https://www.facebook.com/web0967196999/",
        },
        {
          icon: "icon-icon_ig",
          link: "https://www.instagram.com/yongxin_design/",
        },
        {
          icon: "icon-icon_tel",
          link: "tel://0800888533",
        },
        {
          icon: "icon-icon_line",
          link: "https://lin.ee/YOmi96n",
        },
      ],
    };
  },
};
</script>
