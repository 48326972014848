<template>
  <section id="Customer" ref="MainContent" class="relative z-10 w-full">
    <div
      class="w-full max-w-screen-xl mx-auto my-40 xl:w-3/4 px-7 md:px-10 xl:px-0 md:my-60"
    >
      <div class="relative z-10 mb-20 md:mb-48">
        <h2
          data-title
          data-text="還在為架設網站而煩惱？"
          class="text-border-main_green_dark md:text-[80px] text-5xl leading-snug font-black absolute top-0 left-0 z-10"
        >
          還在為架設網站而煩惱？
        </h2>
        <span class="relative opacity-0 md:text-[80px] text-5xl leading-snug"
          >還在為架設網站而煩惱？</span
        >
      </div>

      <div class="relative flex justify-center">
        <ol class="relative flex-shrink-0 w-full md:w-auto">
          <li
            v-for="(item, item_index) in card_list"
            :key="`card_${item_index}`"
            class="flex items-center w-full mb-12 sm:mb-5 md:w-auto md:mb-0 md:absolute md:whitespace-nowrap"
            :class="GetListItemClass(item_index)"
          >
            <div
              data-dialog
              class="relative block w-full sm:w-1/2 md:inline-block md:w-auto"
            >
              <div
                class="relative z-10 px-5 py-5 border border-black rounded rounded-bl-none lg:px-10 bg-main_white"
              >
                <span
                  :class="
                    item_index == 0
                      ? '-left-2'
                      : item_index == 1
                      ? 'left-14'
                      : 'left-14'
                  "
                  class="absolute z-20 w-16 sm:-top-full -top-3/4 sm:-left-1 md:w-20 h-fit"
                >
                  <img
                    :src="item.image"
                    alt="阿隆幫你架網站"
                    class="block w-full"
                  />
                </span>
                <span
                  class="absolute z-10 px-1 text-sm font-bold -top-3 md:text-base text-main_green bg-main_white"
                  >{{ item.title }}</span
                >
                <h3 class="w-full font-bold lg:text-xl">
                  {{ item.content }}
                </h3>
              </div>
              <span
                class="absolute top-0 left-0 right-0 bottom-0 z-0 bg-main_green border border-main_black rounded rounded-bl-none transform translate-x-1.5 md:translate-x-1 translate-y-1.5 md:translate-y-1"
              ></span>
            </div>
          </li>
          <div data-image class="mx-auto w-72 lg:w-80">
            <img
              src="/img/along_img_sad.webp"
              alt="還在為架設網站而煩惱嗎？"
              class="relative z-10 block w-full"
            />
          </div>
        </ol>
      </div>
    </div>
  </section>
</template>

<style scoped>
.text-border-main_green_dark {
  text-shadow: 1px -1px 0 #034524, 1px -1px 0 #034524, -1px 1px 0 #034524,
    1px 1px 0 #034524;
  color: #7ab87a;
}
</style>

<script>
import { section_animation } from "@/gsap/home/section";
export default {
  name: "HomeCustomer",
  data() {
    return {
      card_list: [
        {
          title: "你是否...",
          content: "剛創業想做網站卻沒方向",
          image: "/img/home_customer/customer_txt_deco_1.webp",
        },
        {
          title: "你是否...",
          content: "不滿意現在網站的視覺設計",
          image: "/img/home_customer/customer_txt_deco_2.webp",
        },
        {
          title: "你是否...",
          content: "想配合檔期不定期調整網站",
          image: "/img/home_customer/customer_txt_deco_3.webp",
        },
      ],
      customer_animation: null,
    };
  },
  methods: {
    GetListItemClass(index) {
      if (index == 0) {
        return "md:top-0 md:left-0 transfrom md:-translate-x-full md:pr-0 sm:pr-10";
      } else if (index == 1) {
        return "md:bottom-14 md:left-10 transfrom md:-translate-x-full justify-end md:pl-0 sm:pl-10";
      } else if (index == 2) {
        return "md:bottom-40 lg:right-14 md:right-8 transfrom md:translate-x-full md:pr-0 sm:pr-10";
      }
    },
    SetGsap() {
      this.customer_animation = new section_animation(this.$refs.MainContent);
    },
  },
};
</script>
