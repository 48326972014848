<template>
  <section
    id="Plan"
    ref="MainContent"
    class="relative z-10 w-full overflow-hidden"
  >
    <div
      class="relative z-10 w-full max-w-screen-xl mx-auto my-40 xl:w-3/4 px-7 md:px-10 xl:px-0 md:my-60"
    >
      <div class="relative z-10 flex flex-wrap w-full">
        <div
          class="relative z-10 flex flex-col sm:justify-items-start justify-center order-last w-full xl:w-[40%] md:grow md:shrink sm:-pl-2 md:px-10 xl:pl-20 xl:pr-0"
        >
          <div class="relative z-10 w-full mb-10">
            <h2
              data-title
              data-text="快速開啟電商事業"
              class="absolute top-0 left-0 z-10 w-full text-4xl font-black leading-tight text-justify lg:text-5xl lg:leading-snug text-main_black"
            >
              快速開啟電商事業
            </h2>
            <span
              class="relative z-0 text-4xl text-justify opacity-0 lg:text-5xl"
              >快速開啟電商事業</span
            >
          </div>
          <p
            data-image
            class="w-full mb-10 text-sm font-medium text-justify lg:text-base"
          >
            在創業中最怕的是人家的不認識，擁有網站平台可以將美好形象建立，一眼入魂，等待淺意識中的喚起，他就會成為你的那位專屬客戶。
          </p>
          <div data-image class="relative z-10 w-fit">
            <router-link
              to="/Application"
              class="apply_btn relative z-10 block rounded-full border border-main_yellow bg-main_balck py-2 md:py-3 px-6 md:px-10 transition-all duration-300 transform hover:translate-x-[3px] hover:translate-y-[3px] hover:bg-main_yellow hover:border-main_black"
            >
              <span
                class="relative z-10 mr-2 text-sm font-medium apply_btn_txt md:text-base text-main_yellow"
                >立即申請</span
              >
              <span
                class="relative z-10 text-sm font-medium icon-icon_arrow text-main_yellow"
              ></span>
            </router-link>
            <span
              class="absolute z-0 top-0 left-0 right-0 bottom-0 bg-main_black rounded-full transform translate-x-[3px] translate-y-[3px]"
            ></span>
          </div>
        </div>
        <ul
          class="relative z-10 flex items-stretch flex-wrap xl:w-[60%] md:-mx-2 mb-10 xl:mb-0"
        >
          <li
            data-dialog
            class="relative z-10 w-full mb-10 sm:w-1/2 sm:px-2 xl:mb-0"
            v-for="(item, item_index) in plan_detail"
            :key="`card_${item_index}`"
          >
            <div class="relative z-10 w-full sm:h-full">
              <div
                class="relative z-10 flex flex-col w-full pt-10 border rounded-md sm:h-full px-7 pb-7 border-main_black"
                :class="item_index == 1 ? 'bg-main_white ' : 'bg-main_yellow'"
              >
                <div class="absolute z-10 -top-5 w-fit">
                  <h3
                    class="relative z-10 px-3 py-1 text-xl font-black border rounded w-fit md:text-2xl text-main_black bg-main_white border-main_black drop_shadow"
                    :class="
                      item_index == 1 ? 'bg-main_white ' : 'bg-main_yellow'
                    "
                    v-html="item.name"
                  ></h3>
                  <span></span>
                </div>

                <div class="flex-1">
                  <p
                    class="relative z-10 mb-3 text-base font-medium text-main_black"
                  >
                    {{ item.small }}
                  </p>
                  <!-- <span
                    class="relative z-10 block font-black font-any-body text-main_purple"
                    >NT$
                    <strong
                      class="relative z-10 mb-1 text-4xl align-top font-any-body text-main_purple"
                      >{{ item.price }}</strong
                    >
                  </span> -->
                  <!-- <small class="block mb-7">
                    <p class="text-sm line-through text-main_black">
                      {{ item.price_original }}
                    </p>
                    <strong class="text-sm font-medium text-main_black">{{
                      item.period
                    }}</strong>
                  </small> -->

                  <ul class="relative z-10 w-full sm:justify-stretch mb-7">
                    <li class="w-full">
                      <h3>
                        <span
                          class="mr-2 text-base align-middle icon-star text-main_purple"
                        ></span>
                        <span class="font-bold text-main_purple">
                          {{ item.features_title_1 }}
                        </span>
                      </h3>
                      <p
                        class="mb-3 text-sm font-medium text-justify text-main_black pl-7"
                      >
                        {{ item.features_content_1 }}
                      </p>
                    </li>
                    <li class="w-full">
                      <h3>
                        <span
                          class="mr-2 text-base align-middle icon-star text-main_purple"
                        ></span>
                        <span class="font-bold text-main_purple">
                          {{ item.features_title_2 }}
                        </span>
                      </h3>
                      <p
                        class="mb-3 text-sm font-medium text-justify text-main_black pl-7"
                      >
                        {{ item.features_content_2 }}
                      </p>
                    </li>
                    <li class="w-full">
                      <h3>
                        <span
                          class="mr-2 text-base align-middle icon-star text-main_purple"
                        ></span>
                        <span class="font-bold text-main_purple">
                          {{ item.features_title_3 }}
                        </span>
                      </h3>
                      <p
                        class="mb-3 text-sm font-medium text-justify text-main_black pl-7"
                      >
                        {{ item.features_content_3 }}
                      </p>
                    </li>
                    <li class="w-full">
                      <h3>
                        <span
                          class="mr-2 text-base align-middle icon-star text-main_purple"
                        ></span>
                        <span class="font-bold text-main_purple">
                          {{ item.features_title_4 }}
                        </span>
                      </h3>
                      <p
                        class="mb-3 text-sm font-medium text-justify text-main_black pl-7"
                      >
                        {{ item.features_content_4 }}
                      </p>
                    </li>
                  </ul>
                </div>
                <router-link
                  to="/Application"
                  class="relative z-10 block py-3 text-sm font-bold text-center transition-all duration-500 border text-main_black border-main_black hover:border-dashed hover:bg-main_yellow hover:text-main_black"
                  :class="
                    item_index == 1 ? 'bg-main_purple ' : 'bg-main_yellow'
                  "
                  >了解更多</router-link
                >
              </div>
              <span
                class="w-full h-full absolute z-0 top-0 left-0 transform translate-x-[6px] translate-y-[6px] border rounded-md bg-main_purple border-main_black"
                :class="item_index == 1 ? 'block' : 'hidden'"
              ></span>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <!-- Deco -->
    <span
      class="absolute top-0 right-0 z-0 transform rotate-45 translate-x-20 -translate-y-1/2 w-fit"
    >
      <img
        data-parallax-el
        data-start-x="0"
        data-end-x="0"
        data-start-y="-200"
        data-end-y="50"
        src="/img/home_plan/plan_deco_3.webp"
        alt="阿隆幫你架網站"
        class="w-60 md:w-80"
      />
    </span>
    <span
      class="absolute top-0 right-0 z-0 transform -translate-x-1/2 -translate-y-1/2 w-fit"
    >
      <img
        data-parallax-el
        data-start-x="0"
        data-end-x="0"
        data-start-y="200"
        data-end-y="0"
        src="/img/home_plan/plan_deco_2.webp"
        alt="阿隆幫你架網站"
        class="w-40"
      />
    </span>
    <span
      class="absolute bottom-0 left-0 z-0 transform rotate-45 translate-x-1/2 translate-y-1/2 w-fit"
    >
      <img
        data-parallax-el
        data-start-x="0"
        data-end-x="0"
        data-start-y="200"
        data-end-y="0"
        src="/img/home_plan/plan_deco_3.webp"
        alt="阿隆幫你架網站"
        class="w-60 md:w-80"
      />
    </span>
    <span class="absolute bottom-0 right-0 z-0 transform translate-x-20 w-fit">
      <img
        data-parallax-el
        data-start-x="0"
        data-end-x="0"
        data-start-y="400"
        data-end-y="0"
        src="/img/home_plan/plan_deco_1.webp"
        alt="阿隆幫你架網站"
        class="w-80 md:w-96"
      />
    </span>
    <div class="absolute top-0 bottom-0 left-0 right-0 z-0 plan_bg_noisy"></div>
  </section>
</template>

<style scoped>
#Plan {
  background-image: url("@/assets/img/paln_bg_pattern.webp");
  background-size: 1440px;
  background-repeat: repeat-x;
  background-color: #e2e868;
  background-position: bottom;
}
.plan_bg_noisy {
  background-image: url("@/assets/img/bg_noisy.webp");
  background-repeat: repeat;
  background-size: 100px;
  opacity: 0.2;
}
.drop_shadow {
  /* -webkit-filter: drop-shadow(3px 3px 0px rgba(27, 28, 30, 1)); */
}
.apply_btn:hover .apply_btn_txt,
.apply_btn:hover .icon-icon_arrow {
  color: #1b1c1e;
}
</style>

<script>
import { parallax_el } from "@/gsap/home/parallax_el";
import { section_animation } from "@/gsap/home/section";
export default {
  name: "HomePlan",
  data() {
    return {
      plan_detail: [
        {
          name: "小成本大製作",
          small: "適合新手快速開啟電商之路",
          price: "8,888",
          price_original: "16,800",
          period: "優惠期限至 2023/04/30",
          features_title_1: "素材及文案基礎優化",
          features_content_1:
            "將您所販售的商品圖片及商品文案基礎優惠，讓消費更清楚產品賣點。",
          features_title_2: "網站視覺設計",
          features_content_2:
            "內含首頁、關於我們、產品介紹、聯絡我們等頁面的Banner圖片設計、頁面HTML/CSS微調，讓網頁在套版主題下也能呈現自我獨特風格。",
          features_title_3: "網站建置及上線",
          features_content_3:
            "不怕看不懂的程式碼以及看不懂的教學，從無到有，由我們來幫您快速開啟電商事業。",
          features_title_4: "",
          features_content_4:
            "後台簡約而強大的功能，複雜的操作可以通過簡單的步驟完成，使用者不需要花費太多時間學習。",
        },
        {
          name: "全方位架站",
          small: "適合業務迅速擴展經營方案",
          price: "26,800",
          price_original: "",
          period: "",
          features_title_4: "",
          features_content_4:
            "後台簡約而強大的功能，複雜的操作可以通過簡單的步驟完成，使用者不需要花費太多時間學習。",
          features_title_1: "量身規劃網站",
          features_content_1:
            "客製化規劃網站，從品牌理念、風格著手進行網站發想規劃、圖片設計，使網站更能符合您的品牌形象。",
          features_title_2: "品牌內容行銷",
          features_content_2:
            "協助打造符合您品牌形象的文字內容，吸引更多潛在客戶。優化後的內容，能讓您的品牌更容易被搜尋引擎發現。",
          features_title_3: "網站視覺設計",
          features_content_3:
            "依據時下的設計趨勢，採用UI/UX設計原則，將品牌形象專業打造，以及在瀏覽網站動線上，簡約直觀。",
        },
      ],
      parallax_el_animation: null,
      section_animation: null,
    };
  },
  methods: {
    SetGsap() {
      this.parallax_el_animation = new parallax_el(this.$refs.MainContent);
      this.section_animation = new section_animation(this.$refs.MainContent);
    },
  },
};
</script>
