import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "首頁",
    component: HomeView,
  },
  {
    path: "/Application",
    name: "立即線上申請",
    component: () => import("../views/ApplicationView.vue"),
  },
  {
    path: "/Project/:id",
    name: "作品介紹",
    component: () => import("../views/ProjectDetailView.vue"),
  },
  {
    path: "/PlanDetail",
    name: "製作細項説明",
    component: () => import("../views/PlanDetailView.vue"),
  },
  {
    path: "/Error",
    name: "錯誤頁面",
    component: () => import("../views/ErrorView.vue"),
  },
  {
    path: "/index.php",
    redirect: "/",
  },
  {
    path: "/index.html",
    redirect: "/",
  },
  {
    path: "*",
    redirect: "/Error",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;
