import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    dialog: {
      msg: '',
      status: false,
    },
    menu_status: false,
    image_loaded: false,
    loading: 0,
    case_dialog: {
      status: false, //顯示開館
      name: '',
      outline: '',
      type: '',
      content: '',
      link: '',
    },
  },
  mutations: {
    SetDialog(state, dialog_data) {
      state.dialog.msg = dialog_data.msg;
      state.dialog.status = dialog_data.status;
    },
    SetLoading(state, action) {
      state.loading += action;
    },
    SetImageLoaded(state, action) {
      state.image_loaded = action;
    },
    SetMenuStatus(state, action) {
      state.menu_status = action;
    },
    SetCaseDialog(state, { name, outline, type, content, link, status }) {
      state.case_dialog.name = name;
      state.case_dialog.outline = outline;
      state.case_dialog.type = type;
      state.case_dialog.content = content;
      state.case_dialog.link = link;
      state.case_dialog.status = status;
    },
    CloseCaseDialog(state) {
      state.case_dialog.status = false;
    },
  },
  actions: {},
  getters: {},
});

export default store;
