<template>
  <section
    ref="MainContent"
    class="fixed top-0 z-40 flex items-center justify-center w-full h-screen overflow-hidden transform translate-x-0 left-full"
  >
    <div
      data-dialog-box
      id="MainDialog"
      class="relative z-10 w-full max-w-[400px] opacity-0 mx-7 md:mx-10"
    >
      <div
        class="relative z-10 w-full p-10 border rounded-md md:p-14 bg-main_white border-main_black"
      >
        <p class="w-full mb-5 text-sm font-bold text-center md:text-base">
          {{ dialog_content }}
        </p>
        <button
          @click="CloseDialog"
          class="block px-3 py-1 mx-auto text-sm font-bold transition-colors duration-300 border rounded border-main_black hover:bg-main_yellow"
        >
          關閉
        </button>
      </div>
      <span
        class="absolute top-0 bottom-0 left-0 right-0 z-0 transform translate-x-1 translate-y-1 border border-dashed rounded-md bg-main_green border-main_black"
      ></span>
    </div>

    <div
      data-dialog-bg
      class="absolute top-0 left-0 z-0 w-full h-full opacity-0 dialog_bg bg-main_black bg-opacity-80 transtion-all duraction-300"
    ></div>
  </section>
</template>

<style scoped></style>

<script>
import { dialog_animation } from '@/gsap/dialog';
export default {
  name: 'MainDialog',
  data() {
    return {
      dialog_animation: null,
    };
  },
  methods: {
    CloseDialog() {
      this.$store.commit('SetDialog', {
        msg: this.dialog_content,
        status: false,
      });
    },
  },
  watch: {
    dialog_status() {
      this.dialog_status
        ? this.dialog_animation.open()
        : this.dialog_animation.close();
    },
  },
  mounted() {
    this.dialog_animation = new dialog_animation(this.$refs.MainContent);
  },

  computed: {
    dialog_status() {
      return this.$store.state.dialog.status;
    },
    dialog_content() {
      return this.$store.state.dialog.msg;
    },
  },
};
</script>
