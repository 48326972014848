<template>
  <section
    id="Process"
    ref="MainContent"
    class="relative z-10 w-full overflow-hidden"
  >
    <div
      class="relative z-10 w-full max-w-screen-xl mx-auto my-40 xl:w-3/4 px-7 md:px-10 xl:px-0 md:my-60"
    >
      <header class="relative z-10 w-full mb-20 md:mb-28">
        <div class="relative z-10 mb-4">
          <h2
            data-title
            data-text="簡單步驟完成網站"
            class="md:text-[80px] text-5xl leading-tight absolute top-0 left-0 font-black text-main_yellow text-border-main_green_dark drop_shadow"
          >
            簡單步驟完成網站
          </h2>
          <span class="relative opacity-0 md:text-[80px] text-5xl leading-snug"
            >簡單步驟完成網站</span
          >
        </div>

        <div data-image class="relative inline-block">
          <small
            class="relative z-10 block px-6 py-2 text-base font-bold border rounded w-fit md:text-xl lg:text-2xl font-any-body text-main_green_dark border-main_green_dark bg-main_white drop_shadow"
            >Process</small
          >
          <span
            class="absolute top-0 bottom-0 left-0 right-0 transform translate-x-1 translate-y-1 rounded bg-main_green_dark"
          ></span>
        </div>
        <!-- Deco -->
        <span class="absolute top-0 z-0 -right-1/4 w-fit">
          <img
            data-parallax-el
            data-start-x="0"
            data-end-x="0"
            data-start-y="-200"
            data-end-y="50"
            src="/img/home_process/process_deco_1.webp"
            alt="阿隆幫你架網站"
            class="block w-40"
          />
        </span>
      </header>

      <ul class="relative flex flex-wrap z-8 md:-mx-3">
        <li
          data-dialog
          class="relative z-10 w-full mb-10 md:w-1/2 xl:w-1/4 md:mb-14 md:px-3"
          v-for="(item, item_index) in process_card_list"
          :key="`card_${item_index}`"
        >
          <div class="relative z-10 w-full h-full">
            <div
              class="relative z-10 w-full h-full px-10 pt-5 pb-10 border rounded-md bg-main_white border-main_black"
            >
              <span
                class="absolute z-10 px-1 -top-6 md:-top-8 w-fit h-fit bg-main_white"
              >
                <small class="text-sm font-bold md:text-base font-any-body"
                  >Step</small
                >
                <p
                  class="inline text-4xl font-black md:text-5xl text-main_black font-any-body"
                >
                  {{ item.number }}
                </p>
              </span>

              <h3
                class="mb-3 text-xl font-black md:text-2xl text-main_black md:mb-5"
              >
                {{ item.title }}
              </h3>

              <div class="w-full">
                <img
                  :src="item.image"
                  alt="簡單步驟完成網站"
                  class="w-24 mx-auto md:py-4"
                />
              </div>
            </div>
            <span
              :class="item_index >= 4 ? 'bg-main_yellow' : 'bg-main_green'"
              class="absolute z-0 top-0 left-0 right-0 bottom-0 border border-dashed border-main_black rounded-md transform translate-x-1.5 md:translate-x-1 translate-y-1.5 md:translate-y-1"
            ></span>
          </div>
        </li>
        <!-- Deco -->
        <span class="absolute right-0 z-0 -top-32 w-fit">
          <img
            data-parallax-el
            data-start-x="0"
            data-end-x="0"
            data-start-y="500"
            data-end-y="-200"
            src="/img/home_process/process_deco_2.webp"
            alt="阿隆幫你架網站"
            class="block w-96"
          />
        </span>
        <span class="absolute z-10 bottom-10 -right-7 w-fit">
          <img
            data-parallax-el
            data-start-x="0"
            data-end-x="0"
            data-start-y="300"
            data-end-y="0"
            src="/img/home_process/process_deco_3.webp"
            alt="阿隆幫你架網站"
            class="block w-20"
          />
        </span>
      </ul>
    </div>
  </section>
</template>

<style scoped>
.text-border-main_green_dark {
  text-shadow: 1px -1px 0 #034524, 1px -1px 0 #034524, -1px 1px 0 #034524,
    1px 1px 0 #034524;
}
.drop_shadow {
  /* -webkit-filter: drop-shadow(3px 3px 0px rgba(3, 69, 36, 1)); */
}
</style>

<script>
import { section_animation } from "@/gsap/home/section";
import { parallax_el } from "@/gsap/home/parallax_el";
export default {
  name: "HomeProcess",
  data() {
    return {
      process_card_list: [
        {
          number: "01",
          title: "了解需求",
          image: "/img/home_process/process_icon_step1.webp",
        },
        {
          number: "02",
          title: "提供報價",
          image: "/img/home_process/process_icon_step2.webp",
        },
        {
          number: "03",
          title: "客戶提供資料",
          image: "/img/home_process/process_icon_step3.webp",
        },
        {
          number: "04",
          title: "確認規劃書",
          image: "/img/home_process/process_icon_step4.webp",
        },
        {
          number: "05",
          title: "視覺設計",
          image: "/img/home_process/process_icon_step5.webp",
        },
        {
          number: "06",
          title: "功能設計",
          image: "/img/home_process/process_icon_step6.webp",
        },
        {
          number: "07",
          title: "上線前校對",
          image: "/img/home_process/process_icon_step7.webp",
        },
        {
          number: "08",
          title: "網站上線",
          image: "/img/home_process/process_icon_step8.webp",
        },
      ],
      process_animation: null,
      parallax_el_animation: null,
    };
  },
  methods: {
    SetGsap() {
      this.process_animation = new section_animation(this.$refs.MainContent);
      this.parallax_el_animation = new parallax_el(this.$refs.MainContent);
    },
  },
};
</script>
