import { gsap } from '@/gsap/gsap_loader';

export class parallax_el {
  constructor(el) {
    this.el = el;
    this.parallax_els = el.querySelectorAll('[data-parallax-el]');
    this.timeline = null;
    this.setup();
  }

  setup() {
    this.timeline != null ? this.timeline.kill() : '';
    this.timeline = gsap.timeline({
      scrollTrigger: {
        trigger: this.el,
        start: 'top bottom',
        end: 'bottom top',
        scrub: true,
      },
    });
    this.parallax_els.forEach((item) => {
      const { startX, startY, endX, endY } = item.dataset;
      const tween = gsap.timeline();
      tween.fromTo(
        item,
        {
          x: startX,
          y: startY,
        },
        {
          x: endX,
          y: endY,
        }
      );
      this.timeline.add(tween, 'same');
    });
  }
}
