<template>
  <nav
    ref="MainContent"
    class="fixed top-0 left-0 w-full z-30 sm:h-[100px] h-[80px] flex items-center justify-center"
  >
    <div
      class="relative z-10 flex items-center justify-between w-full max-w-screen-xl mx-auto xl:px-0 md:px-10 px-7"
    >
      <h1 class="w-fit">
        <router-link to="/" class="block w-fit">
          <img
            src="/img/logo.webp"
            class="hidden w-48 sm:block"
            alt="阿隆幫你架網站"
          />
          <img
            src="/img/logo@sm.webp"
            class="block w-10 sm:hidden"
            alt="阿隆幫你架網站"
          />
        </router-link>
      </h1>

      <div class="flex items-center">
        <!-- <div class="relative z-10 hidden mr-3 lg:block">
          <router-link
            to="/PlanDetail"
            class="relative block rounded-full border-main_black border py-3 px-10 z-10 bg-main_white transition-all duration-300 transform hover:translate-x-[2px] hover:translate-y-[2px]"
          >
            <span class="relative z-10 font-bold">製作細項說明</span>
          </router-link>
          <span
            class="absolute top-0 left-0 right-0 bottom-0 z-0 bg-main_black rounded-full transform translate-x-[2px] translate-y-[2px]"
          ></span>
        </div> -->
        <div class="relative z-10 mr-3">
          <router-link
            data-marquee-box
            data-duration="4"
            data-direction="right"
            to="/Application"
            class="marquee_box relative z-10 w-[180px] flex items-center rounded-full border-main_black border bg-main_yellow overflow-hidden transition-all duration-300 transform hover:translate-x-[2px] hover:translate-y-[2px] hover:bg-main_black hover:border-main_orange"
          >
            <span
              data-marquee-item
              class="flex items-baseline flex-shrink-0 w-2/3 px-0 py-3"
              v-for="text_item in 3"
              :key="`text_${text_item}`"
            >
              <span
                class="relative z-10 mr-1 text-sm font-bold marquee_item_txt md:mr-2 md:text-base text-main_black"
                >立即申請</span
              >
              <span
                class="relative z-10 text-xs font-medium icon-icon_arrow md:text-sm text-main_black"
              ></span>
            </span>
          </router-link>
          <span
            class="absolute top-0 left-0 right-0 bottom-0 z-0 bg-main_black rounded-full transform translate-x-[2px] translate-y-[2px]"
          ></span>
        </div>
        <div class="relative z-10 block lg:hidden">
          <button
            @click="$store.commit('SetMenuStatus', !menu_status)"
            class="relative z-10 flex items-center justify-center rounded-full border-main_black border w-12 h-12 bg-main_white transition-all duration-300 transform hover:translate-x-[2px] hover:translate-y-[2px]"
          >
            <img
              v-show="!menu_status"
              src="/img/icon_menu.webp"
              alt="menu"
              class="relative z-10 block w-6"
            />
            <span
              v-show="menu_status"
              class="relative z-10 block w-6 icon-icon_close"
            >
            </span>
            <!-- <span class="relative z-10 font-medium icon-icon_menu"></span> -->
          </button>
          <span
            class="absolute top-0 left-0 right-0 bottom-0 z-0 bg-main_black rounded-full transform translate-x-[2px] translate-y-[2px]"
          ></span>
        </div>
      </div>
    </div>
  </nav>
</template>

<style scoped>
.marquee_box:hover .marquee_item_txt,
.marquee_box:hover .icon-icon_arrow {
  color: #e2e868;
}
</style>

<script>
export default {
  name: "MainHeader",
  computed: {
    menu_status() {
      return this.$store.state.menu_status;
    },
  },
};
</script>
