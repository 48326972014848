<template>
  <section
    id="HomeTopBanner"
    ref="MainContent"
    class="sm:pt-[100px] pt-[80px] relative bg-main_white"
  >
    <div
      class="relative z-10 flex flex-col w-full max-h-screen overflow-hidden aspect-[2/3] md:aspect-square lg:aspect-video 2xl:aspect-[20/9]"
    >
      <div class="relative z-10 w-full max-w-screen-xl mx-auto">
        <div
          data-marquee-box
          data-direction="right"
          data-duration="10"
          class="flex items-center w-full overflow-hidden border-y border-main_green_dark"
        >
          <div
            data-marquee-item
            class="flex items-baseline flex-shrink-0 py-2"
            v-for="text_item in 5"
            :key="`text_${text_item}`"
          >
            <span
              class="flex-shrink-0 mr-5 font-bold leading-none font-any-body text-main_green_dark"
            >
              Along Can Do
            </span>
            <span
              class="flex-shrink-0 mr-5 font-bold leading-none text-main_green"
            >
              網站的事 交給阿隆
            </span>
          </div>
        </div>
      </div>

      <div
        class="relative z-10 flex items-end justify-center flex-1 w-full max-w-screen-xl mx-auto"
      >
        <div
          class="absolute transform top-[10%] left-1/2 -translate-x-1/2 w-full flex justify-center z-10"
        >
          <div data-title-box class="relative opacity-0">
            <img
              data-image-left
              src="/img/home_banner/title_deco_left.webp"
              alt="阿隆幫你架網站"
              class="absolute top-0 w-10 transform -translate-x-full -left-2 lg:-left-5 md:w-16 lg:w-20"
            />
            <img
              data-image-right
              src="/img/home_banner/title_deco_right.webp"
              alt="阿隆幫你架網站"
              class="absolute top-0 w-10 transform translate-x-full -right-2 lg:-right-5 md:w-16 lg:w-20"
            />
            <h2
              data-title
              class="md:text-[80px] sm:text-6xl text-6xl text-main_yellow font-black block relative z-10 text-border-main_green_dark text-center leading-snug"
            >
              小預算 <br class="block md:hidden" />大製作
            </h2>
            <span
              data-title-shadow
              class="md:text-[80px] text-center sm:text-6xl text-6xl text-main_green_dark font-black absolute top-0 left-0 transform md:translate-x-1 md:translate-y-1 translate-x-[2px] translate-y-[2px] z-0 leading-snug"
              >小預算 <br class="block md:hidden" />大製作</span
            >
          </div>
        </div>

        <div
          class="absolute transform top-[10%] left-1/2 -translate-x-1/2 w-full flex justify-center z-10"
        >
          <div data-title-box class="relative opacity-0">
            <img
              data-image-left
              src="/img/home_banner/title_deco_left.webp"
              alt="阿隆幫你架網站"
              class="absolute top-0 w-10 transform -translate-x-full -left-2 lg:-left-5 md:w-16 lg:w-20"
            />
            <img
              data-image-right
              src="/img/home_banner/title_deco_right.webp"
              alt="阿隆幫你架網站"
              class="absolute top-0 w-10 transform translate-x-full -right-2 lg:-right-5 md:w-16 lg:w-20"
            />
            <h2
              data-title
              class="md:text-[80px] sm:text-6xl text-6xl text-main_orange font-black block relative z-10 text-border-main_green_dark text-center leading-snug"
            >
              電商網站<br class="block md:hidden" />一點<br
                class="block md:hidden"
              />都不難
            </h2>
            <span
              data-title-shadow
              class="md:text-[80px] text-center sm:text-6xl text-6xl text-main_green_dark font-black absolute top-0 left-0 transform md:translate-x-1 md:translate-y-1 translate-x-[2px] translate-y-[2px] z-0 leading-snug"
              >電商網站<br class="block md:hidden" />一點<br
                class="block md:hidden"
              />都不難</span
            >
          </div>
        </div>

        <div
          class="absolute bottom-0 z-0 w-full transform -translate-x-1/2 translate-y-1/2 lg:w-3/4 left-1/2"
        >
          <img
            alt="阿隆幫你架網站"
            data-banner-image
            src="/img/home_banner/banner_img.webp"
            class="relative z-10 hidden w-full lg:block"
          />
          <img
            alt="阿隆幫你架網站"
            data-banner-image
            src="/img/home_banner/banner_img@md.webp"
            class="relative z-10 block w-full lg:hidden"
          />
          <img
            alt="阿隆幫你架網站"
            data-banner-text
            src="/img/home_banner/banner_txt_circle.webp"
            class="absolute top-0 left-0 z-0 hidden w-full lg:block"
          />
          <img
            alt="阿隆幫你架網站"
            data-banner-text
            src="/img/home_banner/banner_txt_circle@md.webp"
            class="absolute top-0 left-0 z-0 block w-full lg:hidden"
          />
          <img
            alt="阿隆幫你架網站"
            data-banner-text-outline
            src="/img/home_banner/banner_txt_circle_outline.webp"
            class="absolute top-0 left-0 z-0 hidden w-full lg:block"
          />
          <img
            alt="阿隆幫你架網站"
            data-banner-text-outline
            src="/img/home_banner/banner_txt_circle_outline@md.webp"
            class="absolute top-0 left-0 z-0 block w-full lg:hidden"
          />
        </div>
      </div>

      <!-- DECO IMAGE -->
      <div
        class="absolute left-0 z-[1] hidden transform -translate-y-1/2 w-80 top-1/2 -translate-x-14 lg:block"
      >
        <img
          src="/img/home_banner/deco_left.webp"
          alt="阿隆幫你架網站"
          data-deco-left
          class="block w-full"
        />
      </div>
      <div
        class="absolute left-0 z-[1] block transform -translate-x-5 -translate-y-1/2 w-44 md:w-72 top-1/2 lg:hidden"
      >
        <img
          data-deco-left
          src="/img/home_banner/deco_left@md.webp"
          alt="阿隆幫你架網站"
          class="block w-full"
        />
      </div>

      <div
        class="absolute right-0 z-[1] hidden transform w-72 bottom-32 translate-x-14 lg:block"
      >
        <img
          data-deco-right
          src="/img/home_banner/deco_right.webp"
          alt="阿隆幫你架網站"
          class="block w-full"
        />
      </div>
      <div
        class="absolute right-0 z-[1] block w-40 transform -translate-y-1/2 md:w-64 top-1/2 translate-x-14 lg:hidden"
      >
        <img
          data-deco-right
          src="/img/home_banner/deco_right@md.webp"
          alt="阿隆幫你架網站"
          class="block w-full"
        />
      </div>

      <!-- BG IMAGE -->
      <div
        data-dot-bg-green
        class="absolute top-0 left-0 z-0 w-full h-full green_dot_bg"
      ></div>
      <div
        data-dot-bg-white
        class="absolute top-0 left-0 z-0 w-full h-full opacity-0 white_dot_bg"
      ></div>
    </div>
  </section>
</template>

<style scoped>
#HomeTopBanner .green_dot_bg {
  background-image: url("@/assets/img/bg_pattern.webp");
  background-size: 1440px;
  background-repeat: repeat-x;
  background-position: bottom;
}
#HomeTopBanner .white_dot_bg {
  background-image: url("@/assets/img/bg_pattern_white.webp");
  background-size: 1440px;
  background-repeat: repeat-x;
  background-position: bottom;
}

.text-border-main_green_dark {
  /* -webkit-text-stroke: 1px #034524; */
  text-shadow: 1px -1px 0 #034524, 1px -1px 0 #034524, -1px 1px 0 #034524,
    1px 1px 0 #034524;
}
</style>

<script>
import { top_banner } from "@/gsap/home/top_banner";
export default {
  name: "HomeTopBanner",
  data() {
    return {
      top_banner_animation: null,
    };
  },
  methods: {
    SetGsap() {
      this.top_banner_animation = new top_banner(this.$refs.MainContent);
    },
  },
  mounted() {},
};
</script>
