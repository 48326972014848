import { gsap } from '@/gsap/gsap_loader';
import TextPlugin from 'gsap/TextPlugin';
gsap.registerPlugin(TextPlugin);

export class section_animation {
  constructor(el) {
    this.el = el;
    this.title = el.querySelector('[data-title]');
    this.image = el.querySelectorAll('[data-image]');
    this.dialog_list = el.querySelectorAll('[data-dialog]');
    this.timeline = null;
    this.setup();
  }

  setup() {
    this.timeline != null ? this.timeline.kill() : '';
    this.timeline = gsap.timeline({
      scrollTrigger: {
        trigger: this.el,
        start: 'top 60%',
        toggleActions: 'restart play play reverse',
      },
    });
    this.timeline
      .fromTo(
        this.title,
        {
          text: '',
        },
        {
          text: this.title.dataset.text,
          duration: 1,
        },
        'same'
      )
      .fromTo(
        this.image,
        {
          scale: 0,
        },
        {
          scale: 1,
          ease: 'back.inOut(1.7)',
        },
        'same'
      )
      .fromTo(
        this.dialog_list,
        {
          opacity: 0,
          y: 20,
        },
        {
          opacity: 1,
          y: 0,
          delay: 0.5,
          stagger: 0.1,
        },
        'same'
      );
  }
}
