<template>
  <section id="Feature" ref="MainContent" class="relative z-10 w-full">
    <div
      class="relative z-10 w-full max-w-screen-xl mx-auto my-40 xl:w-3/4 px-7 md:px-10 xl:px-0 md:my-60"
    >
      <header class="relative z-10 w-full mb-20 md:mb-28">
        <div class="relative z-10 mb-4">
          <h2
            data-title
            data-text="阿隆解決您的煩惱"
            class="md:text-[80px] text-5xl leading-tight absolute top-0 left-0 font-black text-main_yellow text-border-main_green_dark drop_shadow"
          >
            阿隆解決您的煩惱
          </h2>
          <span class="relative opacity-0 md:text-[80px] text-5xl leading-snug"
            >阿隆解決您的煩惱</span
          >
        </div>

        <div data-image class="relative inline-block">
          <small
            class="relative z-10 block px-6 py-2 text-base font-bold border rounded w-fit md:text-xl lg:text-2xl font-any-body text-main_green_dark border-main_green_dark bg-main_white drop_shadow"
            >Feature</small
          >
          <span
            class="absolute top-0 bottom-0 left-0 right-0 transform translate-x-1 translate-y-1 rounded bg-main_green_dark"
          ></span>
        </div>
      </header>

      <ul class="relative z-0 flex flex-wrap md:-mx-5">
        <li
          data-dialog
          class="relative z-10 w-full mb-10 md:w-1/2 lg:w-1/3 md:mb-20 lg:mb-0 md:px-5"
          v-for="(item, item_index) in feature_card_list"
          :key="`card_${item_index}`"
        >
          <div class="relative w-full h-full">
            <div
              class="relative z-10 w-full h-full py-10 border rounded-md rounded-bl-none px-7 md:px-10 bg-main_white border-main_black"
            >
              <div
                class="absolute z-10 w-14 md:w-20 h-14 md:h-20 -top-7 md:-top-12"
              >
                <span
                  class="relative z-10 flex items-center justify-center w-full h-full border rounded-full border-main_black bg-main_white"
                >
                  <img
                    :src="item.image"
                    alt="阿隆幫你架網站-我們的優勢"
                    class="w-7 md:w-10"
                  />
                </span>
                <span
                  class="absolute z-0 top-0 left-0 right-0 bottom-0 bg-main_green border border-main_black rounded-full transform translate-x-[3px] translate-y-[3px]"
                ></span>
              </div>

              <h3
                class="relative z-10 mb-2 text-xl font-black md:text-2xl text-main_black md:mb-3"
              >
                {{ item.title }}
              </h3>
              <p
                class="w-full text-sm font-medium text-justify md:text-base text-main_black"
              >
                {{ item.content }}
              </p>
            </div>
            <span
              class="absolute z-0 top-0 left-0 right-0 bottom-0 bg-main_green border border-main_black rounded-md rounded-bl-none transform translate-x-1.5 md:translate-x-1 translate-y-1.5 md:translate-y-1"
            ></span>
          </div>
        </li>
        <!-- section-deco -->
        <span class="absolute right-0 z-0 -bottom-1/4 lg:bottom-1/2 w-fit">
          <img
            data-parallax-el
            data-start-x="0"
            data-end-x="0"
            data-start-y="500"
            data-end-y="-200"
            src="/img/home_feature/feature_deco_2.webp"
            alt="阿隆幫你架網站"
            class="w-96"
          />
        </span>
      </ul>
    </div>
  </section>
</template>

<style scoped>
.text-border-main_green_dark {
  text-shadow: 1px -1px 0 #034524, 1px -1px 0 #034524, -1px 1px 0 #034524,
    1px 1px 0 #034524;
}
.drop_shadow {
  /* -webkit-filter: drop-shadow(3px 3px 0px rgba(3, 69, 36, 1)); */
}
</style>

<script>
import { section_animation } from "@/gsap/home/section";
import { parallax_el } from "@/gsap/home/parallax_el";
export default {
  name: "HomeFeature",
  data() {
    return {
      feature_card_list: [
        {
          title: "低成本 高CP值",
          content:
            "實惠的阿隆架網站方案讓您能夠以較低的成本進入電商領域，因此您能更有效地分配資金以優化獲得的績效。",
          image: "/img/home_feature/feature_icon_cost.webp",
        },
        {
          title: "建立品牌專屬風格",
          content:
            "我們是一群年輕的專業網站設計團隊，擁有多位經驗豐富的網頁設計師，駕馭各式風格。",
          image: "/img/home_feature/feature_icon_branding.webp",
        },
        {
          title: "專人1對1服務",
          content:
            "服務熱忱的心，一對一為您解決你的任何問題，從無到有，讓你不再為需自己架設網站而煩惱。",
          image: "/img/home_feature/feature_icon_service.webp",
        },
      ],
      feature_animation: null,
      parallax_el_animation: null,
    };
  },
  methods: {
    SetGsap() {
      this.feature_animation = new section_animation(this.$refs.MainContent);
      this.parallax_el_animation = new parallax_el(this.$refs.MainContent);
    },
  },
};
</script>
