import Vue from "vue";
import router from "@/router/index";
var default_meta = {
  title: "阿隆幫你架網站 - 小預算大製作，網站的事交給阿隆",
  content:
    "在創業中最怕的是人家的不認識，擁有網站平台可以將美好形象建立，一眼入魂，等待淺意識中的喚起，他就會成為你的那位專屬客戶。",
  image: "https://along.yongxin-design.com/img/share/index.jpg",
};

Vue.prototype.$MoneyFormat = (price) => {
  if (typeof price != "number") {
    return price;
  } else {
    let val = (price / 1).toFixed(0).replace(".", ",");
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
};

Vue.prototype.$GetMetaData = (title, content, image) => {
  title = title == "" ? default_meta.title : title + " - 阿隆幫你架網站";
  content = content == "" ? default_meta.content : content;
  content.length > 155 ? (content = content.slice(0, 155) + "...") : "";
  return {
    title: title,
    meta: [
      {
        property: "og:url",
        name: "og:url",
        content:
          process.env.VUE_APP_BASEURL +
          router.history.current.fullPath.slice(1),
        vmid: "og:url",
      },
      {
        property: "og:title",
        name: "og:title",
        content: title,
        vmid: "og:title",
      },
      {
        property: "og:description",
        name: "og:description",
        content: content,
        vmid: "og:description",
      },
      {
        property: "twitter:description",
        name: "twitter:description",
        content: content,
        vmid: "twitter:description",
      },
      {
        property: "description",
        name: "description",
        content: content,
        vmid: "description",
      },
      {
        property: "og:image",
        name: "og:image",
        content: image == "" ? default_meta.image : image,
        vmid: "og:image",
      },
      {
        property: "apple-mobile-web-app-title",
        name: "apple-mobile-web-app-title",
        content: title,
        vmid: "apple-mobile-web-app-title",
      },
      {
        property: "application-name",
        name: "application-name",
        content: title,
        vmid: "application-name",
      },
      {
        property: "og:site_name",
        name: "og:site_name",
        content: "官方網站",
        vmid: "og:site_name",
      },
      {
        property: "og:type",
        name: "og:type",
        content: "website",
        vmid: "og:type",
      },
      {
        property: "og:locale",
        name: "og:locale",
        content: "zh_tw",
        vmid: "og:locale",
      },
    ],
  };
};

Vue.prototype.$LoadAllFont = () => {
  LoadAllFont();
};
Vue.prototype.$CheckFontLoad = () => {
  CheckFontLoad();
};
Vue.prototype.$SetImage = () => {
  const image_el = document.querySelectorAll("img");
  image_el.forEach((item) => {
    item.alt != "" ? "" : (item.alt = "阿隆幫你架網站");
    item.getAttribute("width")
      ? ""
      : item.setAttribute("width", item.clientWidth);
    item.getAttribute("height")
      ? ""
      : item.setAttribute("height", item.clientHeight);
  });
};

function LoadAllFont() {
  if (
    document.head.querySelectorAll(
      '[href="https://fonts.googleapis.com/css2?family=Anybody:wdth,wght@125,500;125,700&family=Noto+Sans+TC:wght@400;500;700;900&display=swap"]'
    ).length <= 0
  ) {
    let css_link = document.createElement("link");
    css_link.href =
      "https://fonts.googleapis.com/css2?family=Anybody:wdth,wght@125,500;125,700&family=Noto+Sans+TC:wght@400;500;700;900&display=swap";
    css_link.rel = "stylesheet";
    css_link.type = "text/css";
    document.head.appendChild(css_link);
  }
}

function LoadFont(text) {
  if (
    document.head.querySelectorAll(
      '[href="https://fonts.googleapis.com/css2?family=Anybody:wdth,wght@125,500;125,700&family=Noto+Sans+TC:wght@400;500;700;900&display=swap"]'
    ).length <= 0
  ) {
    let notoserif_link = document.createElement("link");
    notoserif_link.href =
      "https://fonts.googleapis.com/css2?family=Anybody:wdth,wght@125,500;125,700&display=swap&text=" +
      encodeURIComponent(text);
    notoserif_link.rel = "stylesheet";
    notoserif_link.type = "text/css";
    document.head.appendChild(notoserif_link);

    let yesevaone_link = document.createElement("link");
    yesevaone_link.href =
      "https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@400;500;700;900&display=swap&text=" +
      encodeURIComponent(text);
    yesevaone_link.rel = "stylesheet";
    yesevaone_link.type = "text/css";
    document.head.appendChild(yesevaone_link);
  }
}

function GetBodyText() {
  let str = document.body.innerText;
  let uniqueChars = "";
  for (let i = 0; i < str.length; i++) {
    if (str.charAt(i) !== " " && uniqueChars.indexOf(str.charAt(i)) === -1) {
      uniqueChars += str[i];
    }
  }
  uniqueChars = uniqueChars.replace(/\s/g, "");
  str = [...new Set(uniqueChars)].join("");

  str = str.replace(
    /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g,
    ""
  );
  return str;
}

function CheckFontLoad() {
  const links = document.head.querySelectorAll("link");
  let font_loaded = false;
  links.forEach((link) => {
    link.href.indexOf("css2?family=") != -1 ? (font_loaded = true) : "";
  });
  if (font_loaded) {
    LoadAllFont();
  } else {
    const body_text = GetBodyText();
    body_text != "" ? LoadFont(body_text) : "";
  }
}
