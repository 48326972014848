<template>
  <div
    ref="MainContent"
    id="PageBackground"
    class="fixed top-0 bottom-0 left-0 right-0 z-0"
  >
    <div
      data-marquee-box
      data-duration="10"
      data-direction="right"
      class="relative z-0 flex items-center w-full h-16 overflow-hidden md:h-24 top-2/3"
    >
      <span
        data-marquee-item
        class="flex flex-shrink-0 pr-5 w-fit h-fit"
        v-for="text_item in 3"
        :key="`text_${text_item}`"
      >
        <img
          src="@/assets/img/bg_txt.webp"
          alt="阿隆幫你架網站"
          class="w-[560px] md:w-[900px]"
        />
      </span>
    </div>
    <div class="absolute top-0 bottom-0 left-0 right-0 z-0 bg_noisy"></div>
  </div>
</template>

<script>
export default {
  name: 'PageBackground',
};
</script>
