<template>
  <section
    id="MenuPageBox"
    ref="MainContent"
    class="fixed top-0 bottom-0 z-40 w-full overflow-hidden left-full"
  >
    <div id="MenuPage" class="relative z-10 flex flex-col w-full h-full">
      <MainHeader class="relative z-10 w-full grow-0 shrink-0" />
      <div class="relative z-10 w-full grow-0 shrink-0">
        <div
          data-marquee-box1
          data-direction="right"
          data-duration="10"
          class="flex items-center w-full overflow-hidden border-y border-main_green_dark"
        >
          <div
            data-marquee-item
            class="flex items-baseline flex-shrink-0 py-2"
            v-for="text_item in 10"
            :key="`text_${text_item}`"
          >
            <span
              class="flex-shrink-0 mr-5 font-bold leading-none font-any-body text-main_green_dark"
            >
              Along Can Do
            </span>
            <span
              class="flex-shrink-0 mr-5 font-bold leading-none text-main_green"
            >
              網站的事 交給阿隆
            </span>
          </div>
        </div>
      </div>

      <div
        data-dialog-box
        class="relative z-10 flex flex-col justify-between w-full pt-10 mb-10 grow shrink px-7 md:px-10 md:mb-20"
      >
        <ul class="relative z-10 w-full">
          <li
            v-for="(item, item_index) in menu_card_list"
            :key="`card_${item_index}`"
            class="relative z-10 w-full mb-5 md:w-3/4 md:mx-auto"
          >
            <router-link
              @click.native="$store.commit('SetMenuStatus', false)"
              :to="item.link"
              class="menu_btn_list relative z-10 w-full flex items-center px-5 md:px-10 py-4 bg-main_yellow border border-main_black rounded-md transition-all duration-300 transform hover:translate-x-[2px] hover:translate-y-[2px] hover:bg-main_black hover:border-main_green"
            >
              <span
                :class="
                  item_index % 2 == 1 ? 'text-main_orange' : 'text-main_green'
                "
                class="mr-3 text-xl icon-star md:text-2xl"
              ></span>
              <p
                v-html="item.page"
                class="w-full mr-3 text-base font-black menu_btn_txt text-main_black"
              >
                立即申請
              </p>
              <span
                class="text-sm font-medium icon-icon_arrow text-main_black"
              ></span>
            </router-link>
            <span
              class="absolute z-0 top-0 left-0 right-0 bottom-0 bg-main_green border border-dashed border-main_black rounded-md transform translate-x-[3px] translate-y-[3px]"
            ></span>
          </li>
        </ul>

        <ul class="relative z-10 w-full">
          <li class="flex items-center w-full mx-auto mb-2 md:w-3/4 md:mb-4">
            <span
              class="px-3 py-1 mr-2 text-xs font-medium border rounded md:text-sm text-main_balck border-main_black shrink-0"
              >地址</span
            >
            <p
              class="text-sm font-bold text-justify md:text-base text-main_balck"
            >
              台中市南屯區龍德路一段180號
            </p>
          </li>
          <li class="flex items-center w-full mx-auto mb-5 md:w-3/4 md:mb-10">
            <span
              class="px-3 py-1 mr-2 text-xs font-medium border rounded md:text-sm text-main_balck border-main_black"
              >電話</span
            >
            <p
              class="text-sm font-bold md:text-base font-any-body text-main_balck"
            >
              04-2471-9799
            </p>
          </li>
          <li class="w-full md:w-3/4 md:mx-auto">
            <ul class="flex w-full">
              <li
                v-for="(item, item_index) in contact_card_list"
                :key="`card_${item_index}`"
                class="relative z-10 w-10 h-10 mr-2 md:w-12 md:h-12 md:mr-4"
              >
                <a
                  :href="item.link"
                  target="blank"
                  class="contact_card_btn relative z-10 w-full h-full flex justify-center items-center rounded bg-main_yellow border border-main_black transition-all duration-300 transform hover:translate-x-[2px] hover:translate-y-[2px] hover:bg-main_black hover:border-main_green"
                >
                  <span
                    :class="item.icon"
                    class="text-xl contact_card_icon md:text-2xl text-main_black"
                  ></span>
                </a>
                <span
                  class="absolute z-0 top-0 left-0 right-0 bottom-0 bg-main_green border border-dashed border-main_black rounded-md transform translate-x-[3px] translate-y-[3px]"
                ></span>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>

    <div data-dialog-bg class="absolute top-0 bottom-0 left-0 right-0 z-0">
      <div
        class="absolute top-0 bottom-0 left-0 right-0 z-[1] menu_bg_noisy"
      ></div>
      <div class="absolute top-0 bottom-0 left-0 right-0 z-0 menu_bg"></div>
    </div>
  </section>
</template>

<style scoped>
.menu_bg {
  background-image: url('@/assets/img/menu_bg_pattern.webp');
  background-size: 1000px;
  background-repeat: repeat-x;
  background-color: #e2e868;
  background-position: top;
}
.menu_bg_noisy {
  background-image: url('@/assets/img/bg_noisy.webp');
  background-repeat: repeat;
  background-size: 50px;
  opacity: 0.2;
}
.contact_card_btn:hover .contact_card_icon,
.menu_btn_list:hover .menu_btn_txt,
.menu_btn_list:hover .icon-icon_arrow {
  color: #e2e868;
}
</style>

<script>
import MainHeader from '@/components/MainHeader.vue';
import { dialog_animation } from '@/gsap/dialog';

export default {
  name: 'MenuPage',
  components: {
    MainHeader,
  },
  data() {
    return {
      menu_card_list: [
        {
          icon: 'icon-star',
          page: '立即申請',
          link: '/Application',
        },
        {
          icon: 'icon-star',
          page: '製作細項說明',
          link: '/PlanDetail',
        },
      ],
      contact_card_list: [
        {
          icon: 'icon-icon_fb',
          link: 'https://www.facebook.com/web0967196999/',
        },
        {
          icon: 'icon-icon_ig',
          link: 'https://www.instagram.com/yongxin_design/',
        },
        {
          icon: 'icon-icon_tel',
          link: 'tel://0424719799',
        },
        {
          icon: 'icon-icon_line',
          link: 'https://lin.ee/YOmi96n',
        },
      ],
      dialog_animation: null,
    };
  },

  watch: {
    menu_status() {
      this.menu_status
        ? this.dialog_animation.open()
        : this.dialog_animation.close();
    },
  },

  computed: {
    menu_status() {
      return this.$store.state.menu_status;
    },
  },
  mounted() {
    this.dialog_animation = new dialog_animation(this.$refs.MainContent);
    window.addEventListener('resize', () => {
      if (this.menu_status) {
        this.$store.commit('SetMenuStatus', false);
      }
    });
  },
};
</script>
