var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{ref:"MainContent",staticClass:"relative z-10 w-full",attrs:{"id":"Customer"}},[_c('div',{staticClass:"w-full max-w-screen-xl mx-auto my-40 xl:w-3/4 px-7 md:px-10 xl:px-0 md:my-60"},[_vm._m(0),_c('div',{staticClass:"relative flex justify-center"},[_c('ol',{staticClass:"relative flex-shrink-0 w-full md:w-auto"},[_vm._l((_vm.card_list),function(item,item_index){return _c('li',{key:`card_${item_index}`,staticClass:"flex items-center w-full mb-12 sm:mb-5 md:w-auto md:mb-0 md:absolute md:whitespace-nowrap",class:_vm.GetListItemClass(item_index)},[_c('div',{staticClass:"relative block w-full sm:w-1/2 md:inline-block md:w-auto",attrs:{"data-dialog":""}},[_c('div',{staticClass:"relative z-10 px-5 py-5 border border-black rounded rounded-bl-none lg:px-10 bg-main_white"},[_c('span',{staticClass:"absolute z-20 w-16 sm:-top-full -top-3/4 sm:-left-1 md:w-20 h-fit",class:item_index == 0
                    ? '-left-2'
                    : item_index == 1
                    ? 'left-14'
                    : 'left-14'},[_c('img',{staticClass:"block w-full",attrs:{"src":item.image,"alt":"阿隆幫你架網站"}})]),_c('span',{staticClass:"absolute z-10 px-1 text-sm font-bold -top-3 md:text-base text-main_green bg-main_white"},[_vm._v(_vm._s(item.title))]),_c('h3',{staticClass:"w-full font-bold lg:text-xl"},[_vm._v(" "+_vm._s(item.content)+" ")])]),_c('span',{staticClass:"absolute top-0 left-0 right-0 bottom-0 z-0 bg-main_green border border-main_black rounded rounded-bl-none transform translate-x-1.5 md:translate-x-1 translate-y-1.5 md:translate-y-1"})])])}),_vm._m(1)],2)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"relative z-10 mb-20 md:mb-48"},[_c('h2',{staticClass:"text-border-main_green_dark md:text-[80px] text-5xl leading-snug font-black absolute top-0 left-0 z-10",attrs:{"data-title":"","data-text":"還在為架設網站而煩惱？"}},[_vm._v(" 還在為架設網站而煩惱？ ")]),_c('span',{staticClass:"relative opacity-0 md:text-[80px] text-5xl leading-snug"},[_vm._v("還在為架設網站而煩惱？")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mx-auto w-72 lg:w-80",attrs:{"data-image":""}},[_c('img',{staticClass:"relative z-10 block w-full",attrs:{"src":"/img/along_img_sad.webp","alt":"還在為架設網站而煩惱嗎？"}})])
}]

export { render, staticRenderFns }