<template>
  <div
    ref="MainContent"
    class="fixed top-0 bottom-0 z-50 flex items-center justify-center w-full left-full"
  >
    <div data-dialog-box class="relative z-10 text-center">
      <img
        src="/img/loading.webp"
        alt="阿隆幫你架網站"
        class="w-32 mb-4 loading_image"
      />
      <p class="text-xl font-bold font-any-body loading_text">LOADING</p>
    </div>
    <div
      data-dialog-bg
      class="absolute top-0 left-0 z-0 w-full h-full bg-white"
    ></div>
  </div>
</template>

<style scoped>
.loading_image {
  animation: loading_image 1s infinite;
}
@keyframes loading_image {
  0%,
  100% {
    transform: scaleX(1);
  }

  50% {
    transform: scaleX(0.96);
  }
}
.loading_text {
  animation: loading_text 0.8s infinite;
}
@keyframes loading_text {
  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0.6;
  }
}
</style>

<script>
import { dialog_animation } from '@/gsap/dialog';
export default {
  name: 'MainLoading',
  data() {
    return {
      dialog_animation: null,
    };
  },
  watch: {
    loading(new_val, old_val) {
      new_val != 0 && old_val == 0
        ? this.dialog_animation.open()
        : new_val == 0
        ? this.dialog_animation.close()
        : '';
    },
  },
  computed: {
    loading() {
      return this.$store.state.loading;
    },
  },
  mounted() {
    this.dialog_animation = new dialog_animation(this.$refs.MainContent);
  },
};
</script>
