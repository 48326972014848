<template>
  <section id="FAQ" ref="MainContent" class="relative z-10 w-full mb-20">
    <div
      class="relative z-10 w-full max-w-screen-xl mx-auto mb-40 xl:w-3/4 px-7 md:px-10 xl:px-0 md:mb-60"
    >
      <div class="relative z-10 w-full">
        <div
          class="relative z-10 w-full py-3 border rounded-md md:py-5 bg-main_white border-main_black"
        >
          <header class="relative z-10 w-full md:mb-10">
            <div
              v-if="window_size > 768"
              data-marquee-box
              data-duration="10"
              data-direction="left"
              class="hidden w-full pb-1 overflow-hidden md:flex"
            >
              <div
                data-marquee-item
                class="flex items-center flex-shrink-0"
                v-for="text_item in 5"
                :key="`text_${text_item}`"
              >
                <span
                  class="flex-shrink-0 mr-5 text-4xl font-black md:text-5xl text-main_white text-border-main_green"
                >
                  常見問答
                </span>
                <span
                  class="flex-shrink-0 block px-10 py-2 mr-5 text-base font-bold border rounded md:text-xl font-any-body text-main_white text-border-main_green bg-main_white border-main_green w-fit"
                >
                  FAQ
                </span>
                <span
                  class="flex-shrink-0 mr-5 text-xl icon-star md:text-2xl text-main_yellow"
                >
                </span>
              </div>
            </div>
            <div class="block text-center md:hidden">
              <span
                class="block mb-4 text-4xl font-black md:text-5xl text-main_white text-border-main_green"
              >
                <span class="text-xl icon-star text-main_yellow"> </span>
                常見問答
                <span class="text-xl icon-star text-main_yellow"> </span>
              </span>
              <span
                class="block px-4 py-1 mx-auto text-base font-bold border rounded md:text-xl font-any-body text-main_white text-border-main_green bg-main_white border-main_green w-fit"
              >
                FAQ
              </span>
            </div>
          </header>

          <ul class="relative z-10 w-full">
            <li
              v-for="(item, item_index) in FAQ_list"
              :key="`card_${item_index}`"
              class="relative z-10 w-full transition-all duration-300 border-b cursor-pointer question_card border-main_black"
            >
              <div
                @click="OpenQuestion(item_index)"
                class="flex items-center w-full px-5 py-5 md:px-10"
              >
                <h3
                  class="w-full mr-5 text-sm font-bold transition-colors duration-300 justify-self-stretch md:text-base"
                >
                  {{ item.question }}
                </h3>
                <span class="icon-icon_open text-main_black"></span>
              </div>
              <div
                :class="
                  open_list.indexOf(item_index) != -1
                    ? 'max-h-screen pb-5'
                    : 'max-h-0 pb-0'
                "
                class="w-full px-5 overflow-hidden transition-all duration-300 md:px-10"
              >
                <p class="text-sm font-medium md:text-base text-main_black">
                  {{ item.answer }}
                </p>
                <a
                  target="_blank"
                  :href="item.link"
                  class="text-sm font-bold underline md:text-base text-main_green underline-offset-4"
                  >{{ item.link_name }}</a
                >
              </div>
            </li>
          </ul>

          <div class="relative z-10 w-full mt-7 md:mt-10">
            <div
              v-if="window_size > 768"
              data-marquee-box
              data-duration="10"
              data-direction="right"
              class="hidden w-full pb-1 overflow-hidden md:flex"
            >
              <div
                data-marquee-item
                class="flex items-center flex-shrink-0"
                v-for="text_item in 5"
                :key="`text_${text_item}`"
              >
                <span
                  class="flex-shrink-0 mr-5 text-4xl font-black md:text-5xl text-main_white text-border-main_green"
                >
                  常見問答
                </span>
                <span
                  class="flex-shrink-0 block px-10 py-2 mr-5 text-base font-bold border rounded md:text-xl font-any-body text-main_white text-border-main_green bg-main_white border-main_green w-fit"
                >
                  FAQ
                </span>
                <span
                  class="flex-shrink-0 mr-5 text-xl icon-star md:text-2xl text-main_yellow"
                >
                </span>
              </div>
            </div>
          </div>
        </div>
        <span
          class="absolute z-0 top-0 left-0 right-0 bottom-0 bg-main_black rounded-md transform translate-x-1.5 translate-y-1.5"
        ></span>
      </div>

      <!-- Deco -->
      <span
        class="absolute z-0 transform -translate-x-1/2 translate-y-1/2 bottom-10 w-fit"
      >
        <img src="/img/faq_deco.webp" alt="阿隆幫你架網站" class="w-80" />
      </span>
    </div>
  </section>
</template>

<style scoped>
.text-border-main_green {
  text-shadow: 1px -1px 0 #7ab87a, 1px -1px 0 #7ab87a, -1px 1px 0 #7ab87a,
    1px 1px 0 #7ab87a;
}

.question_card:hover h3 {
  color: #7ab87a;
}
</style>

<script>
export default {
  name: "HomeFAQ",
  data() {
    return {
      FAQ_list: [
        {
          question: "我想架設網站，但沒有任何想法，希望能有專人給予建議？",
          answer:
            "您只需要至立即申請填寫需專人聯繫表單，將會有專人主動與您聯繫。",
          link_name: "立即申請",
          link: "/Application?type=contact",
        },
        {
          question: "我想架設網站，但不知道如何開始？",
          answer:
            "您只需要至立即申請填寫線上申請表單，將會有專人主動與您聯繫。",
          link_name: "立即申請",
          link: "/Application?type=now",
        },
        {
          question: "除了方案價格會有其他多餘費用嗎？",
          answer:
            "不會唷！並無多餘費用，若您有其他需求是方案內沒涵蓋的內容需要製作，則會在報價時一同說明清楚。",
          link_name: "",
          link: "",
        },
        {
          question: "以上內容找不到我的問題？",
          answer: "立即撥打服務專線或是填寫表單，將有專人與您聯繫",
          link_name: "撥打客服專線",
          link: "tel://0800-888-553",
        },
      ],
      open_list: [],
      window_size: window.innerWidth,
    };
  },
  methods: {
    OpenQuestion(index) {
      // open_list 裡面有沒有這個數字

      if (this.open_list.indexOf(index) != -1) {
        // 已經有了，將其移除
        this.open_list.splice(this.open_list.indexOf(index), 1);
      } else {
        // 目前沒有，新增該數字
        this.open_list.push(index);
      }
    },
    SetGsap() {},
  },
};
</script>
