<template>
  <main id="Home">
    <HomeTopBanner ref="HomeTopBanner" />
    <HomeCustomer ref="HomeCustomer" />
    <HomeFeature ref="HomeFeature" />
    <HomeProcess ref="HomeProcess" />
    <HomePlan ref="HomePlan" />
    <HomeCase ref="HomeCase" />
    <HomeFAQ ref="HomeFAQ" />
  </main>
</template>

<script>
import HomeTopBanner from "@/components/home/TopBanner.vue";
import HomeCustomer from "@/components/home/Customer.vue";
import HomeFeature from "@/components/home/Feature.vue";
import HomeProcess from "@/components/home/Process.vue";
import HomePlan from "@/components/home/Plan.vue";
import HomeCase from "@/components/home/Case.vue";
import HomeFAQ from "@/components/home/FAQ.vue";
import { LoadImage } from "@/gsap/image_loaded";

export default {
  name: "HomeView",
  components: {
    HomeTopBanner,
    HomeCustomer,
    HomeFeature,
    HomeProcess,
    HomePlan,
    HomeCase,
    HomeFAQ,
  },
  data() {
    return {
      meta_data: null,
    };
  },
  methods: {
    SetGsap() {
      const component_list = [
        "HomeTopBanner",
        "HomeCustomer",
        "HomeFeature",
        "HomeProcess",
        "HomePlan",
        "HomeCase",
        "HomeFAQ",
      ];
      component_list.forEach((item) => {
        this.$refs[item].SetGsap();
      });
      this.$emit("set-gsap");
      this.meta_data = this.$GetMetaData("", "", "");
      window.dataLayer.push({
        event: "page_view",
        page_title: this.meta_data.title,
      });
      window.prerenderReady = true;
      if (this.$route.hash) {
        this.ScrollToEl();
      }
      this.$nextTick(() => {
        this.$CheckFontLoad();
        this.$SetImage();
      });
    },
    ScrollToEl() {
      var element = document.getElementById(this.$route.hash.slice(1));
      var headerOffset = 0;
      var elementPosition = element.getBoundingClientRect().top;
      var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    },
  },
  watch: {
    image_loaded() {
      this.image_loaded ? this.SetGsap() : "";
    },
  },
  computed: {
    image_loaded() {
      return this.$store.state.image_loaded;
    },
  },
  mounted() {
    LoadImage();
  },
  metaInfo() {
    return this.meta_data;
  },
};
</script>
